import React, { Fragment } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Content } from "./generic/genericPage.js";
import GenericPostPageWrapper, { GenericPostTemplate } from "./generic/genericPostTemplate";

const FbIframe = styled.iframe`
	width: 100%;
	max-width: 550px;
	min-height: 520px;
`;

const PageContent = ({ page }) => <Fragment>
	<Content content={page.content} />
	{page.link && ~page.link.indexOf("http") ?
		<FbIframe
			src={`https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(page.link)}`}
			scrolling="no"
			frameBorder="0"
			allowtransparency="true"
			allow="encrypted-media" /> : null}
</Fragment>;

export const EventPageTemplate = ({ page }) =>
	<GenericPostTemplate page={page} siteTitle="PREVIEW">
		{PageContent}
	</GenericPostTemplate>;

export default GenericPostPageWrapper({
	type: "קורס/סדנה",
	children: PageContent,
});

export const pageQuery = graphql`
  query EventByIdQuery($id: String!) {
	...SiteTitle
    markdownRemark(id: {eq: $id }) {
		id
      	html
		frontmatter {
			title
			description
			descriptionRaw
			date(formatString: "MMMM DD, YYYY")
			showDate
			featuredpost
			tags
			link
			templateKey
			postId
		}
		fields {
			slug
		}
		...RemoteBannerImage
	}
  }`;
